import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

import mail from "assets/images/SVG/mail.svg";
import tiktok from "assets/images/SVG/tiktokme.svg";
import insta from "assets/images/SVG/instagram.svg";
import phone from "assets/images/SVG/phone.svg";

const ContactPage = () => {
  return (
    <Layout pageName="contact">
      <Helmet>
        <title>Contact</title>
      </Helmet>
      <Container>
        <h1 className="center">Contact</h1>
        <p className="center">Let's get in touch!</p>
        <div className="contact__icon">
          
          <a href="tel:087-6604646" rel="noopener noreferrer">
            <svg className="contact__icon-item">
              <use xlinkHref={`#${phone.id}`} />
            </svg>
          </a>
          <a href="mailto:caimskinandwellness@gmail.com" rel="noopener noreferrer">
            <svg className="contact__icon-item">
              <use xlinkHref={`#${mail.id}`} />
            </svg>
          </a>
          <a href="https://www.tiktok.com/@aoifeeo" rel="noopener noreferrer">
            <svg className="contact__icon-item">
              <use xlinkHref={`#${tiktok.id}`} />
            </svg>
          </a>
          <a href="https://www.instagram.com/caimskinandwellness/"
            target="_blank"
            rel="noopener noreferrer">
            <svg className="contact__icon-item">
              <use xlinkHref={`#${insta.id}`} />
            </svg>
          </a>
        </div>
      </Container>
    </Layout>
  );
};

export default ContactPage;
